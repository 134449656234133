import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { db } from '../../firebaseConfig';
import Particle from "../Particle";
import { getFirestore, collection, query, getDocs, orderBy, startAfter, limit } from 'firebase/firestore/lite';

function BlogTips() {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const postsPerPage = 6;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { tipsList, lastDoc } = await getTips(db, null, postsPerPage);
        setPosts(tipsList);
        setLastVisible(lastDoc);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  function formatDate(dateString) {
    return dateString.split('T')[0];
  }
  const handleLoadMore = async () => {
    if (lastVisible) {
      setLoading(true);
      try {
        const { tipsList, lastDoc } = await getTips(db, lastVisible, postsPerPage);
        setPosts(prevPosts => [...prevPosts, ...tipsList]);
        setLastVisible(lastDoc);
        setCurrentPage(prevPage => prevPage + 1);
      } catch (error) {
        console.error("Error fetching more data: ", error);
      }
      setLoading(false);
    }
  };


function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}



  return (
    <Container>
      <Particle />
      <Row className="home-about-section" id="about">
        <Col>
          <h1 style={{ fontSize: "2.6em", color: "magenta" }}>
            BLOG <span className="purple"> TIPS </span>
          </h1>
          {posts.map(post => (
            <Card key={post.id} style={{ width: '100%', marginBottom: '20px', borderColor: 'magenta' }}>

{post.imgUrl  && post.imgUrl !== '' && (
    <Card.Img variant="top" 
    src={isValidHttpUrl(post.imgUrl) ? post.imgUrl : `https://firebasestorage.googleapis.com/v0/b/bhaiji-web.appspot.com/o/${post.imgUrl}?alt=media`}
    style={{ width: '100%' }} />
)}


              <Card.Body>
                <Card.Title style={{ color: 'magenta' }}>{post.title}</Card.Title>
                <Card.Subtitle >{formatDate(post.post_date)}</Card.Subtitle>
                <Card.Text dangerouslySetInnerHTML={{ __html: post.content }} />
              </Card.Body>
            </Card>
          ))}
          {loading && <p>Loading...</p>}
          {lastVisible && (
            <Button onClick={handleLoadMore} style={{ margin: '20px 0' }}>Load More</Button>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default BlogTips;

async function getTips(db, startAfterDoc, limitVal) {
  const tipsCollection = collection(db, 'tips');
  let queryConstraints = [orderBy('post_date', 'desc'), limit(limitVal)];
  
  if (startAfterDoc) {
    queryConstraints.push(startAfter(startAfterDoc));
  }

  const tipsQuery = query(tipsCollection, ...queryConstraints);
  const tipsSnapshot = await getDocs(tipsQuery);
  const lastVisibleDoc = tipsSnapshot.docs[tipsSnapshot.docs.length - 1];
  const tipsList = tipsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

  return { tipsList, lastDoc: lastVisibleDoc };
}
